body{ background: url('../images/bg.jpg') top center no-repeat; background-size: cover; color: #FFF;}

::-webkit-input-placeholder {opacity:1; color: #FFFFFF;}
:-moz-placeholder{opacity:1; color: #FFFFFF;}
::-moz-placeholder{opacity:1; color: #FFFFFF;}
:-ms-input-placeholder{opacity:1; color: #FFFFFF;} 

/* Color Variable */ 
:root {
    --primary: #00D0B0;
    --secondary: #007664;
    --body: #1D1A40;
    --box: #35354F80;
    --grey: #27273E;
    --light-grey: #FFFFFF29;
    --white: #FFFFFF;
    --dark-blue: #100F27;

    --gunmetal: #2E2E46;
    --crayola: #33334A;
    --charcoal: #35354F;
}
  /* Color Variable */